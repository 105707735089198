"use client";
import Banner from "@/component/HomePage/Banner";
import "./page.css";
import Image from "next/image";
import ProductsweServe from "@/component/HomePage/ProductsweServe";
import ClientareSaying from "@/component/HomePage/ClientareSaying";
import Letstalk from "@/component/HomePage/Letstalk";
import Landingpage from "@/component/Landingpage/landingpage";
import CookieConsentBanner from '@/component/CookieConsent/CookieConsentBanner';

import { useState } from "react";
export default function Homepage({ loaded }) {
  const [landing, setlanding] = useState(loaded == "true" ? false : true);
  return (
    <main>
      {landing ? (
        <Landingpage setlanding={setlanding} />
      ) : (
        <>
          <Banner />
          <ProductsweServe />
          <ClientareSaying />
          <Letstalk />
          <CookieConsentBanner />
        </>
      )}
    </main>
  );
}
