"use client";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslations } from "next-intl";

export default function clientsaying() {
  const t = useTranslations("clientsaying");

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 115000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="clientsaying">
      <div className="containersaying">
        <div className="title_Section">
          <label>{t("title")}</label>
          <p className="para_title">
            {t("content")} <br></br> {t("content1")}
          </p>
        </div>
        <div className="slider_Sec">
          <Slider {...settings}>
            <div className="slider_content">
              <p className="para_title">{t("sidercontent")}</p>
              <p className="slider_name">
                Samuel Joseph <span>Engineer at Xtreme</span>
              </p>
            </div>
            <div className="slider_content">
              <p className="para_title">{t("sidercontent")}</p>
              <p className="slider_name">
                Samuel Joseph <span>Engineer at Xtreme</span>
              </p>
            </div>
            <div className="slider_content">
              <p className="para_title">{t("sidercontent")}</p>
              <p className="slider_name">
                Samuel Joseph <span>Engineer at Xtreme</span>
              </p>
            </div>
            <div className="slider_content">
              <p className="para_title">{t("sidercontent")}</p>
              <p className="slider_name">
                Samuel Joseph <span>Engineer at Xtreme</span>
              </p>
            </div>
            <div className="slider_content">
              <p className="para_title">{t("sidercontent")}</p>
              <p className="slider_name">
                Samuel Joseph <span>Engineer at Xtreme</span>
              </p>
            </div>
            <div className="slider_content">
              <p className="para_title">{t("sidercontent")}</p>
              <p className="slider_name">
                Samuel Joseph <span>Engineer at Xtreme</span>
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
