import "./homepage.css";
import Mechanicalimg from "@/Helper/images/mechanical.png";
import Electrical from "@/Helper/images/electricl.png";
import Water from "@/Helper/images/water.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Image from "next/image";
import MechanicalDetailimg from "@/Helper/images/mechanicalimg.png";
import ElectricalDetailimg from "@/Helper/images/electricalimg.png";
import WaterDetailimg from "@/Helper/images/waterimg.png";
import Link from "next/link";
import EastIcon from '@mui/icons-material/East';
import Eastsideimg from "@/Helper/images/eastsideimg.png";
import {useTranslations} from 'next-intl';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { useParams } from "next/navigation";

export default function ProductweServe() {
    const t = useTranslations('productweserve');
    const {locale} = useParams();

    return (
        <div className="containerinside containerxxl">
            <div className="common_space">
                <div className="ProductweServe">
                    <div className="title_Section fadeupin">
                        <label>{t('title1')}</label>
                        <p className="para_title fadeupin para_titleTop fadeupin">{t('content1')}</p>
                    </div>
                    <div className="product_grid fadeupin">
                        <div className="mechanical">
                            <div className="mechengineer">
                                <Image src={Mechanicalimg} width={100} height={100} alt="Mechanicalimg" />
                                <small className="para_head ">{t('mechtitle')}</small>
                                <div className="part_sec">
                                    <p className="para_title ">{t('mechcontent')} {t('mechcontent1')}</p>
                                </div>
                                <ul>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem1')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem2')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem3')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem4')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem5')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem6')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('mechitem7')}
                                    </li>
                                </ul>
                                <div className="morelist">
                                    <Link href={`/${locale}/products/mechanical`}>
                                        <ArrowCircleRightIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="electricl">
                            <div className="electricengineer">
                                <Image src={Electrical} width={100} height={100} alt="Electrical" />
                                <small className="para_head fadeupin">{t('electrictitle')}</small>
                                <div className="part_sec">
                                    <p className="para_title fadeupin">{t('electricalcontent')}</p>
                                </div>
                                <ul>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem1')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem2')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem3')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem4')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem5')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem6')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('electricitem7')}
                                    </li>
                                </ul>
                                <div className="morelist">
                                    <Link href={`/${locale}/products/water`}>
                                        <ArrowCircleRightIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="water">
                            <div className="waterengineer">
                                <Image src={Water} width={100} height={100} alt="Water" />
                                <small className="para_head fadeupin">{t('watertitle')}</small>
                                <div className="part_sec">
                                    <p className="para_title fadeupin fadeupin">{t('Ewatercontent')}</p>
                                </div>
                                <ul>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem1')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem2')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem3')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem4')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem5')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem6')}
                                    </li>
                                    <li>
                                        <span><ArrowForwardIcon /></span>{t('wateritem7')}
                                    </li>
                                </ul>
                                <div className="morelist">
                                    <Link href={`/${locale}/products/mechanical`}>
                                        <ArrowCircleRightIcon />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="common_space">
                <div className="Productwdetailsec">
                    <div className="mechanicaldetail">
                        <div className="img_sec">
                            <div>
                                <Image src={MechanicalDetailimg} width={300} height={318} alt="MechanicalDetailimg" />
                            </div>
                        </div>
                        <div className="inform_Sec">
                            <small className="para_head fadeupin">{t('mechnicalhead')}</small>
                            <p className="para_title fadeupin">{t('mechanicaldetail')}</p>
                            <p className="para_title fadeupin">{t('mechanicaldetail1')}</p>
                            <Link href={`${locale}/products/mechanical`}> <label>{t('Explore_more')} <Image src={Eastsideimg} width={15} height={9} /></label></Link>
                        </div>
                    </div>
                </div>
                <div className="Productwdetailsec ">
                    <div className="mechanicaldetail electricaldetail">
                        <div className="img_sec">
                            <div>
                                <Image src={ElectricalDetailimg} width={300} height={318} alt="ElectricalDetailimg" />
                            </div>
                        </div>
                        <div className="inform_Sec">
                            <small className="para_head">{t('electricalhead')}</small>
                            <p className="para_title">{t('electricaldetail')}</p>
                            <p className="para_title">{t('electricaldetail1')}</p>
                            <Link href={`${locale}/products/electrical`}> <label>{t('Explore_more')}<Image src={Eastsideimg} width={15} height={9} /></label></Link>
                        </div>
                    </div>
                </div>
                <div className="Productwdetailsec">
                    <div className="mechanicaldetail waterdetail">
                        <div className="img_sec">
                            <div>
                                <Image src={WaterDetailimg} width={300} height={318} alt="WaterDetailimg" />
                            </div>
                        </div>
                        <div className="inform_Sec">
                            <small className="para_head">{t('waterhead')}</small>
                            <p className="para_title">{t('waterdetail')}</p>
                            <p className="para_title">{t('waterdetail1')}</p>
                            <Link href={`${locale}/products/water`}> <label>{t('Explore_more')} <Image src={Eastsideimg} width={15} height={9} /></label></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
