export default function Zonetechvideo() {
  return (
    <video
      autoPlay
      muted
      loop
      width={320}
      height={210}
      className="banner_video2"
    >
      <source src="/video/bannervideo.mp4" type="video/mp4" />
      <track
        src="/video/bannervideo_captions_en.vtt"
        kind="captions"
        srcLang="en"
        label="English"
        default
      />
      <track
        src="/video/bannervideo_captions_cz.vtt"
        kind="captions"
        srcLang="cz"
        label="Čeština"
      />
      Your browser does not support the video tag.
    </video>
  );
}
