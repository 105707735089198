"use client";

import React, { useEffect, useState } from "react";
import Head from "next/head";
import Video from "./Zonetechvideo";
import "./homepage.css";
import EastIcon from "@mui/icons-material/East";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Link from "next/link";
import {useTranslations} from 'next-intl';
import { useRouter ,useParams} from "next/navigation";


const Banner = () => {
  const t = useTranslations('Banner');
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000, 
  };
  const router = useRouter();

  const {locale} = useParams()


  return (
    <>
      <Head>
        <title>Digital Signage Software Company | Signagemint</title>
        <meta
          name="description"
          content="Signagemint offers effective cloud-based digital signage software that can be used to deploy content including integration, apps, schedules, menu boards and advertising. Connect with experts to know more."
        />
        <link rel="canonical" href="https://zonaktech.nbtdemo.com/en" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://zonaktech.nbtdemo.com/en" />
        <meta
          property="og:title"
          content="Digital Signage Software Company | Signagemint"
        />
        <meta
          property="og:description"
          content="Signagemint offers effective cloud-based digital signage software that can be used to deploy content including integration, apps, schedules, menu boards and advertising. Connect with experts to know more."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/diao2msfj/image/upload/v1720089223/og_image2_bxvr9i.png"
        />
        <meta property="og:image:width" content="800" />
        <meta property="og:image:height" content="600" />
      </Head>
      <div className="section_banner">
        <div className="video-background">
          <Video />
        </div>
        <div className="container">
          <div className="slickslider">
            <div className="slider_position">
              <Slider {...settings}>
                <div className="banner_tiltes">
                  <h2>{t('title1')}</h2>
                  <p>{t('content1')}</p>
                  <Link href={`/${locale}/products/mechanical`}>
                  <button className="view_product">
                  {t('button')} <EastIcon />
                  </button>
                  </Link>

                </div>
                <div className="banner_tiltes">
                <h2>{t('title2')}</h2>
                  <p>{t('content2')}</p>
                  <Link href={`/${locale}/products/electrical`}>
                  <button className="view_product">
                  {t('button')}<EastIcon />
                  </button>
                  </Link>
                </div>
                <div className="banner_tiltes">
                <h2>{t('title3')}</h2>
                  <p>{t('content3')}</p>
                  <Link href={`/${locale}/products/water`}>
                  <button className="view_product">
                  {t('button')}<EastIcon />
                  </button>
                  </Link>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
