/** @format */

"use client";
import CustomButton from "@/component/Buttons/CustomButton";
import React, { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiService } from "@/services/api.services";
import ReCAPTCHA from "react-google-recaptcha";
import { useRouter } from "next/navigation";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import EastIcon from "@mui/icons-material/East";
import { useTranslations } from "next-intl";
import { useParams } from "next/navigation";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = (
  <CheckBoxIcon fontSize="small" style={{ color: "#8DC63F" }} />
);

const ContactForm = () => {
  const t = useTranslations("getintouch");

  const router = useRouter();
  const recaptchaRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState("CZ");
  const [isSuccess, setIsSuccess] = useState(false);
  const params = useParams();
  const locale = params.locale || "en";

  const productsList =
    locale === "cz"
      ? [
          { title: "Vše" },
          { title: "Mechanické" },
          { title: "Elektrické" },
          { title: "Voda" },
        ]
      : [
          { title: "All" },
          { title: "Mechanical" },
          { title: "Electrical" },
          { title: "Water" },
        ];

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t("namerequired")),
    products: Yup.array()
      .min(1, t("selectprouctrequired"))
      .required("Products is required"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required(t("emailrequired")),
    phoneNumber: Yup.string()
      .required(t("phonerequired"))
      .test("phone-valid", "Phone number is not valid", (value) =>
        isValidPhoneNumber(value, selectedCountry)
      ),
    message: Yup.string().required(t("messagerequired")),
    recaptcha: Yup.boolean().oneOf([true], t("captcha")).required(""),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      products: [],
      email: "",
      phoneNumber: "",
      message: "",
      recaptcha: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      const filteredProducts = values.products.filter(
        (product) => product !== "All"
      );

      let payload = {
        data: {
          first_name: values.firstName,
          email: values.email,
          products: filteredProducts,
          phone_number: values.phoneNumber,
          message: values.message,
        },
      };

      try {
        console.log("Submitted Values:", payload.data);

        const response = await apiService.postContactUsDetails(payload);
        if (response.status === 200) {
          apiService.sendThankyouMail({ data: payload.data });
          console.log("🚀 ~ onSubmit: ~ payload:", payload);
          setIsSuccess(true);
          setTimeout(() => {
            router.push(`/${locale}/thankyou`);
          }, 900);
        }
        return response;
      } catch (error) {
        console.log("Form Submission Error:", error);
      }
    },
  });

  const onChange = (value) => {
    formik.setFieldValue("recaptcha", !!value);
    console.log("ReCAPTCHA Verified:", formik.values.recaptcha);
  };

  const handleProductChange = (event, value, reason) => {
    const isAllSelected = value.some(
      (v) => v.title === "All" || v.title === "Vše"
    );
  
    if (isAllSelected) {
      if (
        formik.values.products.length !== productsList.length - 1 &&
        formik.values.products.length > 0
      ) {
        formik.setFieldValue("products", []);
      } else {
        formik.setFieldValue(
          "products",
          productsList
            .map((product) => product.title)
            .filter((product) => product !== "All" && product !== "Vše")
        );
      }
    } else {
      formik.setFieldValue(
        "products",
        value.map((v) => v.title)
      );
    }
  };
  

  const sitekey = "6LcxBisqAAAAAAs5j3vE40u-98tFpoJBmKXL3NHy";

  return (
    <div className="container containerxxl">
      <div className="Letstalk">
        <div className="title_Section">
          <label>{t("title")}</label>
          <p className="para_title para_titleTop">
            {t("content")}
            <br></br>
            {t("content1")}
          </p>
        </div>
        <form onSubmit={formik.handleSubmit} className="contactform_container">
          <div className="contactform_container_row">
            <TextInput
              placeholder={t("entername")}
              id="firstName"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorText={formik.touched.firstName && formik.errors.firstName}
            />
            <TextInput
              placeholder={t("enteremail")}
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="contactform_container_row">
            <div className="custom_number_container">
              <PhoneInput
                country={selectedCountry.toLowerCase()}
                name="phoneNumber"
                id="phoneNumber"
                inputProps={{
                  name: "phoneNumber",
                  id: "phoneNumber",
                }}
                copyNumbersOnly
                enableSearch
                isValid
                value={formik.values.phoneNumber}
                onChange={(value, country) => {
                  formik.setFieldValue("phoneNumber", value);
                  setSelectedCountry(country.countryCode.toUpperCase());
                }}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <p className="error-message">
                  {formik.errors.phoneNumber ?? ""}
                </p>
              )}
            </div>
            <div className="auto_complete">
            <Autocomplete
  multiple
  id="checkboxes-tags-demo"
  options={productsList}
  disableCloseOnSelect
  getOptionLabel={(option) => option.title}
  onChange={handleProductChange}
  isOptionEqualToValue={(option, value) =>
    option.title === value.title
  }
  value={productsList.filter(product =>
    formik.values.products.includes(product.title)
  )}
  renderOption={(props, option, { selected }) => {
    const { key, ...optionProps } = props;
    const isAllOption = option.title === "All";
    return (
      <li key={key} {...optionProps}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          checked={
            isAllOption
              ? formik.values.products.length === productsList.length - 1
              : formik.values.products.includes(option.title)
          }
          sx={{
            "&.MuiCheckbox-root": {
              color: "#8DC63F",
            },
            "&.Mui-checked": {
              color: "#8DC63F",
            },
            "&.MuiCheckbox-indeterminate": {
              color: "#8DC63F",
            },
          }}
        />
        {option.title}
      </li>
    );
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      label={
        formik.values.products.length > 0 ? "" : t("enterproduct")
      }
      placeholder={
        formik.values.products.length > 0 ? "" : t("enterproduct")
      }
      error={
        formik.touched.products && Boolean(formik.errors.products)
      }
      helperText={formik.touched.products && formik.errors.products}
    />
  )}
/>
              {formik.touched.products && formik.errors.products && (
                <p className="error-message">{formik.errors.products ?? ""}</p>
              )}
            </div>
          </div>
          <div className="contactform_container_row">
            <TextInput
              placeholder={t("entermessage")}
              id="message"
              name="message"
              value={formik.values.message}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorText={formik.touched.message && formik.errors.message}
              multi={true}
            />
          </div>
          <div className="contactform_container_row contactform_container_row_bottom">
            <div className="contactform_recaptcha_container">
              <ReCAPTCHA
                sitekey={sitekey}
                ref={recaptchaRef}
                onChange={onChange}
              />
              {formik.touched.recaptcha && formik.errors.recaptcha && (
                <p className="error-message">{formik.errors.recaptcha ?? ""}</p>
              )}
            </div>
            <div className="submit_button">
              <CustomButton
                isLoading={formik.isSubmitting}
                Text={isSuccess ? t("submitted") : t("submit")} 
                PrimaryColorBtn
                type="submit"
                style={{ minWidth: "175px" }}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const TextInput = ({
  errorText,
  label,
  placeholder,
  value,
  onChange,
  multi,
  ...props
}) => {
  if (multi) {
    return (
      <div className="input-container">
        <label htmlFor="input-field">{label}</label>
        <textarea
          {...props}
          type="text"
          id="input-field"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          cols="30"
          rows="6"
        />
        {errorText && <p className="error-message">{errorText}</p>}
      </div>
    );
  }
  return (
    <div className="input-container">
      <label htmlFor="input-field">{label}</label>
      <input
        {...props}
        type="text"
        id="input-field"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {errorText && <p className="error-message">{errorText}</p>}
    </div>
  );
};

export default ContactForm;
