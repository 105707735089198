import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useParams } from 'next/navigation';

const CookieConsentBanner = () => {
    const { locale } = useParams();

    const messages = {
        en: {
            message: "We take a responsible approach not only to business, but also to the protection of your privacy. Therefore, we would like to ask you to consent to the processing of cookies on this website. However, we do not overdo it with their use and cookies are primarily used here for the proper functioning of the website and to improve the user experience.",
            allowAll: "Allow All",
        },
        cz: {
            message: "Odpovědně přistupujeme nejen k podnikání, ale i k ochraně vašeho soukromí. Proto vás chceme požádat o souhlas se zpracováním souborů cookies na tomto webu. S jejich používáním to ale nepřeháníme a soubory cookie zde slouží především pro správné fungování webu a ke zlepšení uživatelského zážitku.",
            allowAll: "Povolit všechny",
        }
    };

    const currentMessages = messages[locale] || messages.en;

    const handleAccept = () => {
        console.log("Cookies accepted");
        window.gtag && window.gtag('config', 'G-YOUR-GA-ID');
    };

    return (
        <div className='CookieConsentsec'>
            <CookieConsent
                location="bottom"
                buttonText={currentMessages.allowAll}
                cookieName="cookieConsent"
                expires={365}
                onAccept={handleAccept}
                style={{ background: "rgb(255 255 255)", textAlign: "center" }}
                buttonStyle={{ color: "#fff", backgroundColor: "#8DC63F", borderRadius: "5px", padding: "10px 20px", fontSize: "13px" }}
            >
               <small>
               {currentMessages.message}{" "}
                </small> 
            </CookieConsent>
            </div>
            );
};

            export default CookieConsentBanner;
