import React from "react";
import "./Button.css";
import { CircularProgress } from "@mui/material";

const CustomButton = ({
  Text,
  PrimaryColorBtn,
  PrimaryColorBtn3,
  PrimaryColorBtn2,
  navBar,
  className = "",
  isLoading,
  ...props
}) => {
  return (
    <button
      disabled={isLoading}
      {...props}
      className={` ${className ?? ""} 
       ${isLoading ? "disable" : "not-loading"} ${
        PrimaryColorBtn
          ? "btn_bgPrimaryColor btn-primary-primary "
          : "btn  btn-primary "
      } 
      ${PrimaryColorBtn3 ? "btn_bgPrimaryColor3 btn-primary-primary3 " : ""}
      ${PrimaryColorBtn2 ? "btn_bgPrimaryColor2 btn-primary-primary2 " : ""}
      ${navBar ? "btn_navbar_padding" : ""} btn-common-style`}
    >
      <p className="btn_common not_found">
        {isLoading && (
          <CircularProgress
            sx={{ color: PrimaryColorBtn ? "#fff" : "#226DB4" }}
            size={18}
          />
        )}
        {Text}
      </p>
    </button>
  );
};

export default CustomButton;
